import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`*`}{`Today we will be doing “Fires Gone Bad”, a fundraising workout to
support those affected by the fires in Australia.  `}</strong></p>
    <p><strong parentName="p">{`If you’d like to donate you can
at: `}<a parentName="strong" {...{
          "href": "https://fundraise.redcross.org.au/fundraiser/crossfitperthcomau"
        }}>{`https://fundraise.redcross.org.au/fundraiser/crossfitperthcomau`}</a></strong></p>
    <p>{`5 Rounds of :40 work, :20 rest:`}</p>
    <p>{`Wall Balls (20/14`}{`#`}{`)`}</p>
    <p>{`Russian KBS’s (53/35`}{`#`}{`, eye level)`}</p>
    <p>{`Burpees`}</p>
    <p>{`V Ups`}</p>
    <p>{`Calorie Row`}</p>
    <p>{`Score = total reps/calories.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      